import React from "react";
import "./styles.css";

const BlockIcon = ({ handleClick, isActive }) => {
  return (
    <div className="icon-container list">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container-small element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g
            className={`el-path-small block ${isActive ? "active" : ""}`}
            transform="matrix(21.875,0,0,21.875,256,256)"
          >
            <g transform="matrix(1,0,0,1,-8,-8)">
              <path d="M4.667,8.001C4.49,8.001 4.321,8.072 4.196,8.197C4.071,8.322 4,8.491 4,8.668L4,12.668C4,12.845 4.071,13.014 4.196,13.139C4.321,13.264 4.49,13.335 4.667,13.335L11.334,13.335C11.511,13.335 11.68,13.264 11.805,13.139C11.93,13.014 12,12.845 12,12.668L12,8.668C12,8.491 11.93,8.322 11.805,8.197C11.68,8.072 11.511,8.001 11.334,8.001L4.667,8.001ZM3.253,7.254C3.628,6.879 4.137,6.668 4.667,6.668L11.334,6.668C11.864,6.668 12.373,6.879 12.748,7.254C13.123,7.629 13.334,8.138 13.334,8.668L13.334,12.668C13.334,13.198 13.123,13.707 12.748,14.082C12.373,14.457 11.864,14.668 11.334,14.668L4.667,14.668C4.137,14.668 3.628,14.457 3.253,14.082C2.878,13.707 2.667,13.198 2.667,12.668L2.667,8.668C2.667,8.138 2.878,7.629 3.253,7.254Z" />
              <path d="M7.058,9.723C7.308,9.473 7.647,9.332 8,9.332C8.354,9.332 8.693,9.473 8.943,9.723C9.193,9.973 9.334,10.312 9.334,10.665C9.334,11.019 9.193,11.358 8.943,11.608C8.693,11.858 8.354,11.999 8,11.999C7.647,11.999 7.308,11.858 7.058,11.608C6.807,11.358 6.667,11.019 6.667,10.665C6.667,10.312 6.807,9.973 7.058,9.723Z" />
              <path d="M8,2.001C7.47,2.001 6.961,2.212 6.586,2.587C6.211,2.962 6,3.471 6,4.001L6,7.335C6,7.703 5.702,8.001 5.334,8.001C4.965,8.001 4.667,7.703 4.667,7.335L4.667,4.001C4.667,3.117 5.018,2.269 5.643,1.644C6.268,1.019 7.116,0.668 8,0.668C8.884,0.668 9.732,1.019 10.357,1.644C10.983,2.269 11.334,3.117 11.334,4.001C11.334,4.369 11.035,4.668 10.667,4.668C10.299,4.668 10,4.369 10,4.001C10,3.471 9.79,2.962 9.415,2.587C9.039,2.212 8.531,2.001 8,2.001Z" />
            </g>
          </g>
        </svg>

        <div className="tooltip-small">
          <p>{isActive ? "Unblock" : "Block"}</p>
        </div>
      </a>
    </div>
  );
};
export default BlockIcon;
