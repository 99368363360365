import React from "react";
import "./styles.css";

const InviteIcon = ({ isActive, handleClick }) => {
  return (
    <div className="icon-container icon-margin-btm" id="invite">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g transform="matrix(1.79021,0,0,1.79021,53.7063,19.6923)">
            <circle className={`el-circle ${isActive ? "active" : ""}`} cx="113" cy="132" r="143" />
          </g>
          <g className="el-path" transform="matrix(13.9375,0,0,13.9375,256,256)">
            <g transform="matrix(1,0,0,1,-12,-12)">
              <path d="M19.536,3.464C18.598,2.527 17.326,2 16,2C14.674,2 13.402,2.527 12.464,3.464C11.527,4.402 11,5.674 11,7C11,8.326 11.527,9.598 12.464,10.536C13.402,11.473 14.674,12 16,12C17.326,12 18.598,11.473 19.536,10.536C20.473,9.598 21,8.326 21,7C21,5.674 20.473,4.402 19.536,3.464ZM16,4C16.796,4 17.559,4.316 18.121,4.879C18.684,5.441 19,6.204 19,7C19,7.796 18.684,8.559 18.121,9.121C17.559,9.684 16.796,10 16,10C15.204,10 14.441,9.684 13.879,9.121C13.316,8.559 13,7.796 13,7C13,6.204 13.316,5.441 13.879,4.879C14.441,4.316 15.204,4 16,4Z" />
              <path d="M18,16C18.796,16 19.559,16.316 20.121,16.879C20.684,17.441 21,18.204 21,19L21,21C21,21.552 21.448,22 22,22C22.552,22 23,21.552 23,21L23,19C23,17.674 22.473,16.402 21.536,15.464C20.598,14.527 19.326,14 18,14L14,14C12.801,14 11.699,14.423 10.838,15.126C10.41,15.475 10.346,16.105 10.695,16.533C11.044,16.961 11.674,17.025 12.102,16.676C12.621,16.253 13.279,16 14,16L18,16Z" />
              <path d="M5,16C5,15.448 5.448,15 6,15C6.552,15 7,15.448 7,16L7,18L9,18C9.552,18 10,18.448 10,19C10,19.552 9.552,20 9,20L7,20L7,22C7,22.552 6.552,23 6,23C5.448,23 5,22.552 5,22L5,20L3,20C2.448,20 2,19.552 2,19C2,18.448 2.448,18 3,18L5,18L5,16Z" />
            </g>
          </g>
        </svg>
        {!isActive && (
          <div className="tooltip">
            <p>Invite people</p>
            <div className="triangle"></div>
          </div>
        )}
      </a>
    </div>
  );
};
export default InviteIcon;
