import React, {useState} from "react";
import "./styles.css";
import FloatingContainer from "../FloatingContainer";
import copyIcon from "../../../assets/img/copy.png";

const infoModal = ({ onClose, roomId, attendeeId }) => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  const handleCopy = (text, id) => {
    navigator.clipboard.writeText(text).then(() => {
      setActiveTooltip(id);
      setTimeout(() => setActiveTooltip(null), 4000); // Reset after 2 seconds
    });
  };

  const renderCopyIcon = (text, id) => (
    <div className="copy-icon-container" onClick={() => handleCopy(text, id)}>
      <img width={16} src={copyIcon} alt="Copy" />
      <div className={`tooltip-copy ${activeTooltip === id ? 'active' : ''}`}>
        <p>{activeTooltip === id ? 'Copied!' : 'Copy'}</p>
        <div className="triangle-copy"></div>
      </div>
    </div>
  );

  return (
    <FloatingContainer className="invite-menu" offset="invite" onClose={onClose} instructions="">
      <div id="invite-cont" className="column">
        <p className="menu-title">Invite People</p>
        <p className="info-modal-text">
          As an admin, you have two types of users to invite. You can copy and share the codes below with them to
          participate and stay engaged!
        </p>

        <div className="menu-box-container margin-btm column">
          <p className="menu-box-title">Active users</p>
          <p className="menu-box-text margin-btm">Who can talk, enter VR, and interact by grabbing elements.</p>
          <div className="code-container row">
            <p className="invite-code">{roomId}</p>
                        {renderCopyIcon(roomId, 'active-users')}

          </div>
        </div>

        <div className="menu-box-container column">
          <p className="menu-box-title">Others - Attendees - Listeners</p>
          <p className="menu-box-text margin-btm">
            Who can listen to the discussion or presentation but cannot actively participate in VR.
          </p>
          <div className="code-container row">
            <p className="invite-code">{attendeeId}</p>
            {renderCopyIcon(attendeeId, 'attendees')}

          </div>
        </div>
      </div>
    </FloatingContainer>
  );
};
export default infoModal;
