import React from "react";
import "./styles.css";

const ElementsIcon = ({ isActive, handleClick }) => {
  return (
    <div className="icon-container">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g transform="matrix(1.79021,0,0,1.79021,53.7063,19.6923)">
            <circle className={`el-circle ${isActive ? "active" : ""}`} cx="113" cy="132" r="143" />
          </g>
          <g className="el-path" transform="matrix(13.9371,0,0,13.9371,256,256)">
            <g transform="matrix(1,0,0,1,-12,-12)">
              <path d="M20,16.303L20,7.7C20,7.349 19.816,7.023 19.515,6.843L12.515,3.217C12.198,3.027 11.802,3.027 11.486,3.217L4.486,6.843C4.184,7.023 4,7.349 4,7.7L4,16.303C4,16.654 4.184,16.98 4.486,17.16L11.486,21C11.802,21.19 12.198,21.19 12.515,21L19.515,17.16C19.816,16.98 20,16.654 20,16.303ZM3.457,5.128C2.553,5.67 2,6.646 2,7.7L2,16.303C2,17.357 2.553,18.333 3.457,18.875L10.457,22.715C11.407,23.285 12.593,23.285 13.544,22.715L20.544,18.875C21.447,18.333 22,17.357 22,16.303L22,7.7C22,6.646 21.447,5.67 20.544,5.128L13.544,1.502C12.593,0.932 11.407,0.932 10.457,1.502L3.457,5.128Z" />
              <path d="M3.77,6.095C3.291,5.819 2.68,5.983 2.404,6.461C2.128,6.939 2.291,7.551 2.77,7.827L11,12.579L11,22C11,22.552 11.448,23 12,23C12.552,23 13,22.552 13,22L13,12.579L21.23,7.827C21.708,7.551 21.872,6.939 21.596,6.461C21.32,5.983 20.708,5.819 20.23,6.095L12,10.846L3.77,6.095Z" />
            </g>
          </g>
        </svg>
        {!isActive && (
          <div className="tooltip">
            <p>Elements</p>
            <div className="triangle"></div>
          </div>
        )}
      </a>
    </div>
  );
};
export default ElementsIcon;
