import React from "react";
import "./styles.css";

const RecenterIcon = ({ handleClick }) => {
  return (
    <div className="icon-container list">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container-small element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g className="el-path-small" transform="matrix(21.875,0,0,21.875,256,256)">
            <g transform="matrix(1,0,0,1,-8,-8)">
              <path d="M7.058,7.058C7.308,6.808 7.647,6.668 8,6.668C8.354,6.668 8.693,6.808 8.943,7.058C9.193,7.309 9.334,7.648 9.334,8.001C9.334,8.355 9.193,8.694 8.943,8.944C8.693,9.194 8.354,9.335 8,9.335C7.647,9.335 7.308,9.194 7.058,8.944C6.807,8.694 6.667,8.355 6.667,8.001C6.667,7.648 6.807,7.309 7.058,7.058Z" />
              <path d="M4,3.333C3.823,3.333 3.654,3.404 3.529,3.529C3.404,3.654 3.333,3.823 3.333,4L3.333,5.333C3.333,5.702 3.035,6 2.667,6C2.298,6 2,5.702 2,5.333L2,4C2,3.47 2.211,2.961 2.586,2.586C2.961,2.211 3.47,2 4,2L5.333,2C5.702,2 6,2.298 6,2.667C6,3.035 5.702,3.333 5.333,3.333L4,3.333Z" />
              <path d="M2.667,10C3.035,10 3.333,10.299 3.333,10.667L3.333,12C3.333,12.177 3.404,12.346 3.529,12.471C3.654,12.596 3.823,12.667 4,12.667L5.333,12.667C5.702,12.667 6,12.965 6,13.333C6,13.701 5.702,14 5.333,14L4,14C3.47,14 2.961,13.789 2.586,13.414C2.211,13.039 2,12.53 2,12L2,10.667C2,10.299 2.298,10 2.667,10Z" />
              <path d="M10,2.667C10,2.298 10.299,2 10.667,2L12,2C12.53,2 13.039,2.211 13.414,2.586C13.789,2.961 14,3.47 14,4L14,5.333C14,5.702 13.701,6 13.333,6C12.965,6 12.667,5.702 12.667,5.333L12.667,4C12.667,3.823 12.596,3.654 12.471,3.529C12.346,3.404 12.177,3.333 12,3.333L10.667,3.333C10.299,3.333 10,3.035 10,2.667Z" />
              <path d="M13.333,10C13.701,10 14,10.299 14,10.667L14,12C14,12.53 13.789,13.039 13.414,13.414C13.039,13.789 12.53,14 12,14L10.667,14C10.299,14 10,13.701 10,13.333C10,12.965 10.299,12.667 10.667,12.667L12,12.667C12.177,12.667 12.346,12.596 12.471,12.471C12.596,12.346 12.667,12.177 12.667,12L12.667,10.667C12.667,10.299 12.965,10 13.333,10Z" />
            </g>
          </g>
        </svg>

        <div className="tooltip-small">
          <p>Recenter</p>
        </div>
      </a>
    </div>
  );
};
export default RecenterIcon;
