import React from "react";
import "./styles.css";
import FloatingContainer from "../FloatingContainer";
import Switch from "../Switch";
import Button from "../Button";


const UsersMenu = ({
  onClose,
  onUpdateGrabbingPermissions,
  users,
  isAdmin,
  toggleAudioChannel,
  isConnected,
  isAudioEnabled,
  roomId,
  attendeeId,
  toggleMute,
  toggleMuteGuests,
  areGuestsMuted,
  isSessionMuted,
}) => {
  const onEnableAudio = () => {
    toggleAudioChannel();
  };

  return (
    <FloatingContainer offset="users" onClose={onClose} className="users">
      <div className="column">
        <p className="menu-title">User Settings</p>
      </div>
      {isAdmin && isConnected && (
        <div className="menu-box-container margin-btm row audio-switch-container">
          <div className="column">
            <p className="menu-box-title big">Audio channel</p>
            <p className="menu-box-text">Enable audio call between active users.</p>
          </div>
          <Switch id="audio" isEnabled={isAudioEnabled} handleClick={onEnableAudio} />
        </div>
      )}

      <div className="menu-box-container column">
        <p className="menu-box-title big">Active users ({users.length})</p>

        {users.length === 0 && <p className="empty-list-msg">Currently there are no users in VR</p>}

        {users.length > 0 && (
          <div className="list-container column">
            <div className="scroll-list">
              {users.map((user) => (
                <div className="user-row row" key={`${user.id}`}>
                  <div className="row">
                    <div className="user-color" style={{ backgroundColor: user.color }} />
                    <p className="user-name">{user.name}</p>
                  </div>
                  <div className="row">
                    <p className="menu-box-text">Grab</p>
                    <Switch
                      id={user.id}
                      isEnabled={user.canGrab}
                      handleClick={onUpdateGrabbingPermissions}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* {elements.length > 0 && isAdmin && (
        <div className="switch-row row">
          <p className="list-text">Grab multiple elements</p>
          <Switch
            id="mutiple-objs"
            isEnabled={canGrabMultipleObjs}
            handleClick={onUpdateGrabbingMultipleObjs}
          />
        </div>
      )} */}
    </FloatingContainer>
  );
};
export default UsersMenu;
