import React from "react";
import "./styles.css";

const ZoomOutIcon = ({ handleClick }) => {
  return (
    <div className="icon-container list">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container-small element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g className="el-path-small delete" transform="matrix(21.875,0,0,21.875,256,256)">
            <g transform="matrix(1,0,0,1,-8,-8)">
              <path d="M2,4.667C2,4.298 2.298,4 2.667,4L13.333,4C13.701,4 14,4.298 14,4.667C14,5.035 13.701,5.333 13.333,5.333L2.667,5.333C2.298,5.333 2,5.035 2,4.667Z" />
              <path d="M6.667,6.668C7.035,6.668 7.333,6.966 7.333,7.335L7.333,11.335C7.333,11.703 7.035,12.001 6.667,12.001C6.298,12.001 6,11.703 6,11.335L6,7.335C6,6.966 6.298,6.668 6.667,6.668Z" />
              <path d="M9.334,6.668C9.702,6.668 10,6.966 10,7.335L10,11.335C10,11.703 9.702,12.001 9.334,12.001C8.965,12.001 8.667,11.703 8.667,11.335L8.667,7.335C8.667,6.966 8.965,6.668 9.334,6.668Z" />
              <path d="M3.278,4.002C3.645,3.972 3.967,4.244 3.998,4.611L4.665,12.611C4.666,12.63 4.667,12.648 4.667,12.667C4.667,12.844 4.737,13.013 4.862,13.138C4.987,13.263 5.157,13.333 5.334,13.333L10.667,13.333C10.844,13.333 11.013,13.263 11.138,13.138C11.264,13.013 11.334,12.844 11.334,12.667C11.334,12.648 11.335,12.63 11.336,12.611L12.003,4.611C12.033,4.244 12.355,3.972 12.722,4.002C13.089,4.033 13.362,4.355 13.331,4.722L12.667,12.697C12.659,13.217 12.449,13.713 12.081,14.081C11.706,14.456 11.198,14.667 10.667,14.667L5.334,14.667C4.803,14.667 4.295,14.456 3.919,14.081C3.552,13.713 3.342,13.217 3.334,12.697L2.669,4.722C2.639,4.355 2.911,4.033 3.278,4.002Z" />
              <path d="M5.724,1.723C5.974,1.473 6.313,1.332 6.666,1.332L9.333,1.332C9.687,1.332 10.026,1.473 10.276,1.723C10.526,1.973 10.666,2.312 10.666,2.665L10.666,4.665C10.666,5.034 10.368,5.332 10,5.332C9.631,5.332 9.333,5.034 9.333,4.665L9.333,2.665L6.666,2.665L6.666,4.665C6.666,5.034 6.368,5.332 6,5.332C5.631,5.332 5.333,5.034 5.333,4.665L5.333,2.665C5.333,2.312 5.473,1.973 5.724,1.723Z" />
            </g>
          </g>
        </svg>

        <div className="tooltip-small">
          <p>Delete</p>
        </div>
      </a>
    </div>
  );
};
export default ZoomOutIcon;
