import React from "react";
import "./styles.css";

const ZoomOutIcon = ({ handleClick }) => {
  return (
    <div className="icon-container list">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container-small element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g className="el-path-small" transform="matrix(21.875,0,0,21.875,256,256)">
            <g transform="matrix(1,0,0,1,-8,-8)">
              <path d="M4.625,1.738C5.272,1.47 5.966,1.332 6.666,1.332C7.367,1.332 8.06,1.47 8.707,1.738C9.354,2.006 9.942,2.399 10.438,2.894C10.933,3.389 11.326,3.977 11.594,4.624C11.862,5.271 12,5.965 12,6.665C12,7.366 11.862,8.059 11.594,8.706C11.326,9.353 10.933,9.941 10.438,10.437C9.942,10.932 9.354,11.325 8.707,11.593C8.06,11.861 7.367,11.999 6.666,11.999C5.966,11.999 5.272,11.861 4.625,11.593C3.978,11.325 3.39,10.932 2.895,10.437C2.4,9.941 2.007,9.353 1.739,8.706C1.471,8.059 1.333,7.366 1.333,6.665C1.333,5.965 1.471,5.271 1.739,4.624C2.007,3.977 2.4,3.389 2.895,2.894C3.39,2.399 3.978,2.006 4.625,1.738ZM6.666,2.665C6.141,2.665 5.621,2.769 5.136,2.97C4.65,3.171 4.209,3.466 3.838,3.837C3.466,4.208 3.172,4.649 2.971,5.135C2.77,5.62 2.666,6.14 2.666,6.665C2.666,7.191 2.77,7.711 2.971,8.196C3.172,8.681 3.466,9.122 3.838,9.494C4.209,9.865 4.65,10.16 5.136,10.361C5.621,10.562 6.141,10.665 6.666,10.665C7.192,10.665 7.712,10.562 8.197,10.361C8.682,10.16 9.123,9.865 9.495,9.494C9.866,9.122 10.161,8.681 10.362,8.196C10.563,7.711 10.666,7.191 10.666,6.665C10.666,6.14 10.563,5.62 10.362,5.135C10.161,4.649 9.866,4.208 9.495,3.837C9.123,3.466 8.682,3.171 8.197,2.97C7.712,2.769 7.192,2.665 6.666,2.665Z" />
              <path d="M4,6.667C4,6.298 4.298,6 4.667,6L8.667,6C9.035,6 9.333,6.298 9.333,6.667C9.333,7.035 9.035,7.333 8.667,7.333L4.667,7.333C4.298,7.333 4,7.035 4,6.667Z" />
              <path d="M9.528,9.527C9.789,9.267 10.211,9.267 10.471,9.527L14.471,13.527C14.731,13.788 14.731,14.21 14.471,14.47C14.211,14.73 13.789,14.73 13.528,14.47L9.528,10.47C9.268,10.21 9.268,9.788 9.528,9.527Z" />
            </g>
          </g>
        </svg>

        <div className="tooltip-small">
          <p>Scale down</p>
        </div>
      </a>
    </div>
  );
};
export default ZoomOutIcon;
