import React from "react";
import "./styles.css";

const ExitIcon = ({ isActive, handleClick }) => {
  return (
    <div className="icon-container icon-margin-right">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g transform="matrix(1.79021,0,0,1.79021,53.7063,19.6923)">
            <circle
              className={`el-circle ${isActive ? "active" : ""}`}
              cx="113"
              cy="132"
              r="143"
            />
          </g>

          <g transform="matrix(16.2222,0,0,16.2222,61.3333,61.3333)">
            <path
              className="el-path"
              d="M10.79,16.29C11.18,16.68 11.81,16.68 12.2,16.29L15.79,12.7C16.18,12.31 16.18,11.68 15.79,11.29L12.2,7.7C11.81,7.31 11.18,7.31 10.79,7.7C10.4,8.09 10.4,8.72 10.79,9.11L12.67,11L4,11C3.45,11 3,11.45 3,12C3,12.55 3.45,13 4,13L12.67,13L10.79,14.88C10.4,15.27 10.41,15.91 10.79,16.29ZM19,3L5,3C3.89,3 3,3.9 3,5L3,8C3,8.55 3.45,9 4,9C4.55,9 5,8.55 5,8L5,6C5,5.45 5.45,5 6,5L18,5C18.55,5 19,5.45 19,6L19,18C19,18.55 18.55,19 18,19L6,19C5.45,19 5,18.55 5,18L5,16C5,15.45 4.55,15 4,15C3.45,15 3,15.45 3,16L3,19C3,20.1 3.9,21 5,21L19,21C20.1,21 21,20.1 21,19L21,5C21,3.9 20.1,3 19,3Z"
            />
          </g>
        </svg>
        {!isActive && (
          <div className="tooltip-btm">
            <p>Exit</p>
            <div className="triangle-btm"></div>
          </div>
        )}
      </a>
    </div>
  );
};
export default ExitIcon;
