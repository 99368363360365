import React from "react";
import "./styles.css";

const UsersIcon = ({ isActive, handleClick }) => {
  return (
    <div className="icon-container icon-margin-btm">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g transform="matrix(1.79021,0,0,1.79021,53.7063,19.6923)">
            <circle className={`el-circle ${isActive ? "active" : ""}`} cx="113" cy="132" r="143" />
          </g>
          <g transform="matrix(13.9375,0,0,13.9375,256,256)">
            <g className="el-path" transform="matrix(1,0,0,1,-12,-12)">
              <path d="M8.464,3.464C9.402,2.527 10.674,2 12,2C13.326,2 14.598,2.527 15.536,3.464C16.473,4.402 17,5.674 17,7C17,8.326 16.473,9.598 15.536,10.536C14.598,11.473 13.326,12 12,12C10.674,12 9.402,11.473 8.464,10.536C7.527,9.598 7,8.326 7,7C7,5.674 7.527,4.402 8.464,3.464ZM12,4C11.204,4 10.441,4.316 9.879,4.879C9.316,5.441 9,6.204 9,7C9,7.796 9.316,8.559 9.879,9.121C10.441,9.684 11.204,10 12,10C12.796,10 13.559,9.684 14.121,9.121C14.684,8.559 15,7.796 15,7C15,6.204 14.684,5.441 14.121,4.879C13.559,4.316 12.796,4 12,4Z" />
              <path d="M10,16C9.204,16 8.441,16.316 7.879,16.879C7.316,17.441 7,18.204 7,19L7,21C7,21.552 6.552,22 6,22C5.448,22 5,21.552 5,21L5,19C5,17.674 5.527,16.402 6.464,15.464C7.402,14.527 8.674,14 10,14L14,14C15.326,14 16.598,14.527 17.536,15.464C18.473,16.402 19,17.674 19,19L19,21C19,21.552 18.552,22 18,22C17.448,22 17,21.552 17,21L17,19C17,18.204 16.684,17.441 16.121,16.879C15.559,16.316 14.796,16 14,16L10,16Z" />
            </g>
          </g>
        </svg>
        {!isActive && (
          <div className="tooltip">
            <p>Users</p>
            <div className="triangle"></div>
          </div>
        )}
      </a>
    </div>
  );
};
export default UsersIcon;
